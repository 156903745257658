<template>
  <div style="background-color: #4f1d00" class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-badge
      v-if="getMe !== null && getMe.roles[0] === 'ROLE_USER'"
      :variant="getMe.isActive === false ? 'danger' : 'success'"
      class="badge-glow align-items-center mr-4"
    >
      {{ getMe.isActive === false ? 'Your account is not activated. Please make a subscription' : 'Your account is activated' }}
    </b-badge>

    <b-button
      v-if="getMe !== null && getMe.roles[0] === 'ROLE_USER'"
      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
      variant="outline-success"
      class="mr-2"
      pill
      @click="open"
    >
      Make a subscription
    </b-button>

    <b-navbar-nav
      v-if="getMe !== null"
      class="nav align-items-center ml-auto"
    >
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <span class="user-status text-white"> {{ getMe.roles[0] === 'ROLE_MANAGER' ? 'Manager' : getMe.roles[0] === 'ROLE_USER' ? 'User' : 'ADMIN' }} </span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/13-small.png')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <!-- <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item> -->

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logOut"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Log out</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
    <BSidebar
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
    >
      <template #header>
        Demande
      </template>
      <template #body>
        <validation-observer ref="simpleRules">
          <b-form class="p-2">
            <b-row>

              <!--  This field is required-->
              <b-col md="12">
                <b-form-group>
                  <label>Université/Institution</label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="institutions"
                  >
                    <b-form-input
                      v-model="demande.institution"
                      type="number"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Université/Institution"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group>
                  <label>Word</label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="word"
                  >
                    <b-form-input
                      v-model="demande.word"
                      type="number"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Word"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group>
                  <label>Nom</label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="nom"
                  >
                    <b-form-input
                      v-model="demande.nom"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Nom"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group>
                  <label>Université</label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="université"
                  >
                    <b-form-input
                      v-model="demande.universite"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Université"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group>
                  <label>Domaine</label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="domaine"
                  >
                    <b-form-input
                      v-model="demande.domaine"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Domaine"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group>
                  <label>Pays</label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="pays"
                  >
                    <v-select
                      v-model="demande.pays"
                      label="libelle"
                      :state="errors.length > 0 ? false:null"
                      :options="getList"
                      :reduce="item => `/api/pays/${item.id}`"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group>
                  <label>TOTAL H INDEX</label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="totalHindex"
                  >
                    <b-form-input
                      v-model="demande.totalHindex"
                      type="number"
                      :state="errors.length > 0 ? false:null"
                      placeholder="TOTAL H INDEX"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group>
                  <label>TOTAL i10 INDEX</label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="totalIndex"
                  >
                    <b-form-input
                      v-model="demande.totalIndex"
                      type="number"
                      :state="errors.length > 0 ? false:null"
                      placeholder="TOTAL i10 INDEX"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group>
                  <label>TOTAL CITATION</label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="totalCitation"
                  >
                    <b-form-input
                      v-model="demande.totalCitation"
                      type="number"
                      :state="errors.length > 0 ? false:null"
                      placeholder="TOTAL CITATION"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- submit button -->
              <b-col cols="12">
                <b-button
                  variant="primary"
                  type="submit"
                  @click.prevent="validationForm"
                >
                  <span v-if="loading === false"> Soumettre </span>
                  <span v-else>
                    <b-spinner small />
                  </span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </template>
    </BSidebar>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  openKkiapayWidget,
} from 'kkiapay'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email,
} from '@validations'
import {
  BLink, BFormInput, BRow, BCol, BBadge, BFormGroup, BForm, BButton, BSpinner, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import Ripple from 'vue-ripple-directive'
import BSidebar from '@/@core/components/b-sidebar/BSidebar.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BLink,
    BNavbarNav,
    BBadge,
    BRow,
    BCol,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    ValidationProvider,
    ValidationObserver,
    BSidebar,
    BFormInput,
    BFormGroup,
    BForm,
    BButton,
    BSpinner,
    vSelect,

    // Navbar Components
    DarkToggler,
  },
  directives: {
    Ripple,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      demande: {
        word: 0,
        nom: '',
        universite: '',
        domaine: '',
        totalHindex: 0,
        totalIndex: 0,
        totalCitation: 0,
        institution: 0,
        pays: '',
        etat: 'En attente',
      },
      required,
      email,
      loading: false,
      isAddNewUserSidebarActive: false,
    }
  },
  computed: {
    getList() {
      return this.$store.getters['pays/list']
    },
    getMe() {
      return this.$store.getters['peoples/info']
    },
  },
  mounted() {
    if (this.$store.getters['peoples/info'] === null) {
      this.$store.dispatch('peoples/getMe')
    }
    this.$store.dispatch('pays/list', { params: null })
  },
  methods: {
    open() {
      openKkiapayWidget({
        amount: 25000,
        api_key: 'dcd72e5069b811ea846ef9e90b19d812',
        sandbox: false,
        phone: '',
      })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          // eslint-disable-next-line radix
          this.demande.institution = parseInt(this.demande.institution)
          // eslint-disable-next-line radix
          this.demande.totalHindex = parseInt(this.demande.totalHindex)
          // eslint-disable-next-line radix
          this.demande.totalIndex = parseInt(this.demande.totalIndex)
          // eslint-disable-next-line radix
          this.demande.totalCitation = parseInt(this.demande.totalCitation)
          // eslint-disable-next-line radix
          this.demande.word = parseInt(this.demande.word)
          this.loading = true
          await this.$store.dispatch('demandes/add', { page: 1, item: this.demande, params: null })
          this.loading = false
          this.demande = {
            word: 0,
            nom: '',
            universite: '',
            domaine: '',
            totalHindex: 0,
            totalIndex: 0,
            totalCitation: 0,
            institution: 0,
            pays: '',
            etat: 'En attente',
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Operation effectuée',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        }
      })
    },
    logOut() {
      localStorage.clear()
      this.$router.push('/login')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

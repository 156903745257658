export default [
  {
    title: 'Tableau de bord',
    route: 'home',
    icon: 'PieChartIcon',
  },
  {
    title: 'Utilisateur',
    route: 'users',
    icon: 'UserIcon',
    resource: 'manage',
    action: 'all',
  },
  {
    title: 'Scientifiques',
    route: 'scientists',
    icon: 'ThermometerIcon',
    resource: 'manage',
    action: 'all',
  },
  {
    title: 'Informations',
    route: 'information',
    icon: 'ServerIcon',
    resource: 'manager',
    action: 'read',
  },
  {
    title: 'Annonces',
    route: 'annonces',
    icon: 'InboxIcon',
    resource: 'manage',
    action: 'all',
  },
]
